import React, { useCallback, useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { ProductCard } from "./components/product-card";
import { SuccessMessage } from "./components/success-message";
import { RefundMessage } from "./components/refund-message";
import { twMerge } from "./utils";
import { NotifyMessage } from "./components/notify-message";
import { getSubdomainIndex, fetchCloudflareTraceData } from "./utils";
import useFacebookPixel from "./hooks/useFacebookPixel";

const CLIENT_SECRET = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(CLIENT_SECRET);

const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

function getUTMParameters() {
  let params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content'),
  }
}

export const CheckoutForm = () => {
  const isFirstLoad = useRef(true);
  const [firstLoad, setFirstLoad] = useState(isFirstLoad);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [checkoutKey, setCheckoutKey] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const latestQuantityRef = useRef(quantity);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fbclid = urlParams.get("fbclid");
  const facebookPixel = useFacebookPixel();
  let fbc = null;
  if (fbclid) {
    const subdomainIndex = getSubdomainIndex();
    const creationTime = Date.now();
    fbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
  }

  useEffect(() => {
    if (facebookPixel) {
      facebookPixel.trackEvent("PageView");
      facebookPixel.trackEvent("InitiateCheckout", {
        quantity: quantity,
      });
    }
  }, [facebookPixel]);

  const fetchClientSecret = useCallback(() => {
    setLoading(true);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fbclid = urlParams.get("fbclid");
    if (fbclid) {
      const subdomainIndex = getSubdomainIndex();
      const creationTime = Date.now();
      fbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
    }
    console.log(fbc);
    // Make a POST request to get the latest active session or create a new one
    return fetch(`${API_GATEWAY_URL}/stripeCheckout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: process.env.REACT_APP_PRICE_ID,
        shippingId: process.env.REACT_APP_SHIPPING_COST_ID,
        couponId: process.env.REACT_APP_COUPON_ID,
        quantity: latestQuantityRef.current,
        fbc,
        utm: getUTMParameters()
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setTimeout(() => {
          setLoading(false);
          setFirstLoad(false);
          isFirstLoad.current = false;
        }, 2000);
        setClientSecret(data.clientSecret);
        return data.clientSecret;
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          setFirstLoad(false);
          isFirstLoad.current = false;
        }, 2000);
        console.error("Failed to fetch client secret", err);
      });
  }, []);

  useEffect(() => {
    latestQuantityRef.current = quantity;
  }, [quantity]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const qtyParam = urlParams.get("qty");
    if (qtyParam) {
      const newQuantity = parseInt(qtyParam, 10);
      setQuantity(newQuantity);
    }
  }, [location]);

  useEffect(() => {
    latestQuantityRef.current = quantity;
    fetchClientSecret();
  }, [fetchClientSecret, quantity]);

  const onQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
    navigate(`?qty=${newQuantity}`);
    setCheckoutKey((prevKey) => prevKey + 1);
  };

  const onComplete = () => {
    fetchClientSecret();
  };

  const options = {
    fetchClientSecret,
    onComplete,
  };

  if (loading) {
  }

  return (
    <div>
      <div
        id="checkout"
        className="relative pb-7 pt-0 custom-lg:pt-7 font-custom"
      >
        <ProductCard quantity={quantity} onQuantityChange={onQuantityChange} />
        {clientSecret && (
          <EmbeddedCheckoutProvider
            key={`provider-${checkoutKey}`}
            stripe={stripePromise}
            options={options}
          >
            <EmbeddedCheckout key={`checkout-${checkoutKey}`} />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      {loading && (
        <div className="min-w-[100vw] fixed top-0 left-0 right-0 bottom-0 z-50">
          <div className="flex flex-col custom-lg:flex-row items-stretch w-full h-full">
            <div
              className={twMerge(
                "w-full custom-lg:w-1/2 pb-[169px] custom-lg:pb-0",
                firstLoad ? "bg-custom-bg" : "bg-custom-transparent"
              )}
            >
              <div
                className={twMerge(
                  "flex flex-1 items-center justify-center",
                  "custom-lg:items-start custom-lg:justify-end pr-10 px-6",
                  !firstLoad ? "opacity-0" : "opacity-100"
                )}
              >
                <div className="w-full max-w-[412px] custom-lg:max-w-[620px] space-y-4 mt-8">
                  <div className="h-8 w-32 rounded-2xl bg-neutral-200/50 delay-75"></div>
                  <div className="h-8 w-36 rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-10 w-full rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="space-y-6">
                    <div className="h-80 w-full bg-neutral-200/50 rounded-2xl p-4 animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full custom-lg:w-1/2 bg-custom-bg">
              <div className="flex flex-1 items-center justify-center custom-lg:items-start custom-lg:justify-start pr-8">
                <div className="w-full space-y-8 mt-8 max-w-[412px] custom-lg:max-w-[450px]">
                  <div className="h-12 w-1/2 rounded-2xl bg-neutral-200/50"></div>
                  <div className="h-14 w-full rounded-2xl bg-neutral-200/50 delay-75"></div>
                  <div className="h-11 w-1/2 rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-20 w-full rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-12 w-1/2 rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-16 w-full rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-11 w-1/2 rounded-2xl bg-neutral-200/50 delay-100"></div>
                  <div className="h-20 w-full rounded-2xl bg-neutral-200/50 delay-100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Return = () => {
  const [loading, setLoading] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ss = urlParams.get("session_id");
  const [sessionId, setSessionId] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const facebookPixel = useFacebookPixel();

  useEffect(() => {
    if (facebookPixel) {
      fetchCloudflareTraceData().then((data) => {
        facebookPixel.trackEvent("Purchase", {
          sessionData: sessionData,
          sessionId: sessionId,
          ip: data.ip,
        });
      });
    }
  }, [facebookPixel]);

  useEffect(() => {
    if (sessionData) {
      let utm = {};
      try {
        utm = JSON.parse(sessionData?.metadata?.utm || '{}')
      } catch (e) {
        // ignore
      }
      window.dataLayer.push({
        event: 'purchase',
        value: sessionData?.amount_total,
        currency: sessionData?.currency,
        transaction_id: sessionId,
        utm_source: utm?.utm_source,
        utm_medium: utm?.utm_medium,
        utm_campaign: utm?.utm_campaign
      });
    }
  }, [sessionData])

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    fetch(`${API_GATEWAY_URL}/stripeCheckout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId: sessionId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSessionData(data.metadata);
        console.log(data.metadata.created);
        setSessionId(data.metadata.created.toString());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to fetch client secret", err);
      });

    // fetchCloudflareTraceData().then((data) => {
    //   facebookPixel.trackEvent('Purchase', {
    //     sessionData: sessionData,
    //     sessionId: sessionId,
    //   });
    // });
  }, [sessionId]);

  if (!loading) {
    return (
      <div className="bg-white flex flex-col items-stretch custom-lg:bg-custom-bg min-h-screen min-w-[100vw]">
        <header className="container p-4 px-8 w-full mx-auto">
          <img src="/assets/sparq-logo-mini.svg" className="w-[35px]" />
        </header>
        <div className="flex flex-col items-center justify-center flex-1 pb-12">
          <SuccessMessage sessionId={sessionId} />
        </div>
      </div>
    );
  }

  return null;
};

const Refund = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pid = urlParams.get("pid");

    fetch(`${API_GATEWAY_URL}/stripeRefund`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pid: pid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.metadata.created);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to fetch client secret", err);
      });
  }, []);

  if (!loading) {
    return (
      <div className="bg-white flex flex-col items-stretch custom-lg:bg-custom-bg min-h-screen min-w-[100vw]">
        <header className="container p-4 px-8 w-full mx-auto">
          <img src="/assets/sparq-logo-mini.svg" className="w-[35px]" />
        </header>
        <div className="flex flex-col items-center justify-center flex-1 pb-12">
          <RefundMessage />
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white flex flex-col items-stretch custom-lg:bg-custom-bg min-h-screen min-w-[100vw]">
        <header className="container p-4 px-8 w-full mx-auto">
          <img src="/assets/sparq-logo-mini.svg" className="w-[35px]" />
        </header>
        <div className="flex flex-col items-center justify-center flex-1 pb-12">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
};

const Notify = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");

    fetch(`${API_GATEWAY_URL}/updateProfile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        properties: { support_notification_consent: true },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.metadata.created);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to fetch client secret", err);
      });
  }, []);

  if (!loading) {
    return (
      <div className="bg-white flex flex-col items-stretch custom-lg:bg-custom-bg min-h-screen min-w-[100vw]">
        <header className="container p-4 px-8 w-full mx-auto">
          <img src="/assets/sparq-logo-mini.svg" className="w-[35px]" />
        </header>
        <div className="flex flex-col items-center justify-center flex-1 pb-12">
          <NotifyMessage />
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white flex flex-col items-stretch custom-lg:bg-custom-bg min-h-screen min-w-[100vw]">
        <header className="container p-4 px-8 w-full mx-auto">
          <img src="/assets/sparq-logo-mini.svg" className="w-[35px]" />
        </header>
        <div className="flex flex-col items-center justify-center flex-1 pb-12">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
};

const App = () => {
  return (
    <div className="App bg-custom-bg min-h-screen min-w-full">
      <Router>
        <Routes>
          <Route path="/" element={<CheckoutForm />} />
          <Route path="/return" element={<Return />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/notify" element={<Notify />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
